import { useState,useEffect } from "react";
import { doc, setDoc,getDocs,collection,updateDoc,getDoc } from "firebase/firestore";
import { db } from "./Firebase";
import { where,query } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./Firebase";
import { Tooltip } from "react-tooltip";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-tooltip/dist/react-tooltip.css';


const Dodaj = () => {
  const [ime, setIme] = useState("");
  const [kratakOpis, setKratakOpis] = useState("");
  const [opis, setOpis] = useState("");
  const [cijena, setCijena] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [extraImages, setExtraImages] = useState([]);
  const [manualLink, setManualLink] = useState(null);
  const [vrstaProdukta, setVrstaProdukta] = useState("Klima");
  const [discount, setDiscount] = useState("");
  const [products, setProducts] = useState([]);
  const [categorys, setCategory] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [akcijaValue, setAkcijaValue] = useState(null);
  const [tagovi, setTagovi] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try{
      const docRefTag = collection(db,'Tagovi');
      const snapshot = await getDocs(docRefTag);
      const Tagovi = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTagovi(Tagovi);
      console.log(tagovi);
    } catch (error) {
      console.error(error);
    }
  }    
    fetchData()}, []);
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const proizvodiRef = collection(db, 'Proizvodi');
        const snapshot = await getDocs(proizvodiRef);
        const products = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProducts(products);
      } catch (error) {
        console.error(error);
      }
    }    
    fetchProducts();
  }, []);

  const handleTagChange = (event) => {
    const options = event.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSelectedTags(selectedValues);
  };

  
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const kategorijeRef = collection(db, 'Kategorije');
        const snapshot = await getDocs(kategorijeRef);
        const categorys = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCategory(categorys);
      } catch (error) {
        console.error(error);
      }
    }    
    fetchCategory();
  }, []);

  const handleProductSelect = (event) => {
    const productId = event.target.value;
    const product = products.find((prod) => prod.id === productId);
    setSelectedProduct(product);
  };



  

  const handleAkcijaChange = (event) => {
    const value = Number(event.target.value);
    setAkcijaValue(value);
  };
  
  const handleAkcijaAdd = async (e) => {
    e.preventDefault();
    if (!selectedProduct) {
      return;
    }
    try {
      const productRef = doc(db, "Proizvodi", selectedProduct.id);
      await updateDoc(productRef, { akcija: akcijaValue });

      const querySnapshot = await getDocs(
        query(collection(db, vrstaProdukta), where("ime", "==", selectedProduct.ime))
      );
      const promises = querySnapshot.docs.map((docsss) => {
        const docRef = doc(db, vrstaProdukta, docsss.id);
        return updateDoc(docRef, { akcija: akcijaValue });
      });
      await Promise.all(promises);
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };
  
  
  

  const handleAkcijaDelete = async (e) => {
    e.preventDefault();
    if (!selectedProduct) {
      return;
    }
    try {
      const productRef = doc(db, 'Proizvodi', selectedProduct.id);
      const productDoc = await getDoc(productRef);
      const productData = productDoc.data();
      if (productData.akcija) {
        await updateDoc(productRef, { akcija: null });
        const vrstaProduktaRef = collection(db, vrstaProdukta);
        const querySnapshot = await getDocs(
          query(collection(db, vrstaProdukta), where("ime", "==", selectedProduct.ime))
        );
        const promises = querySnapshot.docs.map((docsss) => {
          const docRef = doc(db, vrstaProdukta, docsss.id);
          return updateDoc(docRef, { akcija: null });
        });
        await Promise.all(promises);
      } else {
        alert('U ovom polju ne postoji akcija');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };
  


  const handlePriceChange = (event) => {
    setCijena(parseInt(event.target.value));
  };

  const handleExtraImagesChange = (event) => {
    const files = Array.from(event.target.files);
    setExtraImages(files);
  };

  const Send = async (e) => {
    e.preventDefault();
    try {
      // Upload the selected file to Firebase Storage and get the download URL
      const storageRef = ref(storage, `images/${selectedFile.name}`);
      const snapshot = await uploadBytes(storageRef, selectedFile);
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Upload the extra images to Firebase Storage and get their download URLs
      const extraImagesData = [];
      for (let i = 0; i < extraImages.length; i++) {
        const extraImageRef = ref(
          storage,
          `extraImages/${extraImages[i].name}`
        );
        const extraImageSnapshot = await uploadBytes(
          extraImageRef,
          extraImages[i]
        );
        const extraImageUrl = await getDownloadURL(extraImageSnapshot.ref);
        extraImagesData.push(extraImageUrl);
      }

      let Manualurl = null;
    if (manualLink) {
      const manualStorage = ref(storage, `Manuali/${manualLink.name}`);
      const snapshotManual = await uploadBytes(manualStorage, manualLink);
      Manualurl = await getDownloadURL(snapshotManual.ref);
    }

      
      // Save the product data and image download URLs to Firestore
      const productData = {
        ime,
        opis,
        cijena,
        manualLink: Manualurl,
        kratakOpis,
        image: downloadURL,
        tagovi: selectedTags,
        extraImages: extraImagesData,
        vrstaProdukta,
      };

      await setDoc(doc(db, "Proizvodi", ime), productData);
      await setDoc(doc(db, vrstaProdukta, ime), productData);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const PromjeniDiscount = async (e) => {
    e.preventDefault();
    try{
      const DiscountValue = discount;
      await updateDoc(doc(db, "DiscountBanner", "Banner1"), {
        TekstBannera : DiscountValue
      });
    }
    catch (error) {
      console.error(error);
    }
  }


  return (
    <div className="create">
      <h2>Dodaj proizvod</h2>
      <a 
      className="Pomoc"
      href="none"
  data-tooltip-id="my-tooltip" 
  data-tooltip-content="Za dodavanje novog proizvoda obavezno je da unesete ime proizvode, oba opisa, cijenu, sliku proizvoda, i da odaberete vrstu proizvoda ostalo je opcionalno." 
  data-tooltip-place="top"
>
<a href="google.com"><FontAwesomeIcon className='Pomoc' icon={faQuestionCircle}/></a>
</a>
<Tooltip id="my-tooltip" />
      <form onSubmit={Send}>
        <label>Ime proizvoda</label>
        <input
          type="text"
          required
          value={ime}
          onChange={(e) => setIme(e.target.value)}
        />
        <label>Kratak opis proizvoda</label>
        <input
          type="text"
          required
          value={kratakOpis}
          onChange={(e) => setKratakOpis(e.target.value)}
        />
        <label>Opis proizvoda</label>
        <input
          type="text"
          required
          value={opis}
          onChange={(e) => setOpis(e.target.value)}
        />
        <label>Cijena proizvoda</label>
        <input type="number" required value={cijena} onChange={handlePriceChange} />
        <label>Slika proizvoda</label>
        <input type="file" required onChange={(e) => setSelectedFile(e.target.files[0])} />
        <label>Dodatne slike</label>
        <input type="file" multiple onChange={handleExtraImagesChange} />
        <label>Link na priručnik</label>
        <input type="file"  onChange={(e) => setManualLink(e.target.files[0])} />
        <label>
          Tagovi:
          <div style={{ position: "relative" }}>
            <select
              style={{ display: "none"}}
              multiple
              value={selectedTags}
              onChange={handleTagChange}
            >
              {tagovi.map((tag) => (
                <option key={tag.id} value={tag.id}>
                  {tag.ime}
                </option>
              ))}
            </select>
            <div>
              {tagovi.map((tag) => (
                <div
                  key={tag.id}
                  style={{ color: "black", padding: "8px", backgroundColor: selectedTags.includes(tag.id) ? "#00BFAE" : "white" }}
                  onClick={() => setSelectedTags(selectedTags.includes(tag.id) ? selectedTags.filter((id) => id !== tag.id) : [...selectedTags, tag.id])}
                >
                  {tag.ime}
                </div>
              ))}
            </div>
          </div>
        </label>
        <label>Vrsta proizvoda</label>
        <select value={vrstaProdukta} onChange={(e) => setVrstaProdukta(e.target.value)}>
        {categorys.map((product) => (
          <option key={product.id} value={product.id}>
            {product.ime}
          </option>
        ))}
        </select>
        <button type="submit">Dodaj proizvod</button>
      </form>
      <br></br>
      <hr></hr>
      <br></br>
      <h2>Izmjeni akcijski banner</h2>
      <a 
      className="Pomoc"
      href="none"
  data-tooltip-id="my-tooltip2" 
  data-tooltip-content="U ovome izmjenujete tekst koji se nalazi u žutoj traci, ako želite da nema žute trake samo kliknite promjeni i refrešujte stranicu i ono će nestati i ako želite dodati neki novi tekst samo upišite ga i kliknite promjeni" 
  data-tooltip-place="top"
>
<a href="google.com"><FontAwesomeIcon className='Pomoc' icon={faQuestionCircle}/></a>
</a>
<Tooltip id="my-tooltip2" />
      <form onSubmit={PromjeniDiscount}>
        <label>Discount Banner</label>
        <input type="text" value={discount} onChange={(e) => setDiscount(e.target.value)}>

        </input>
        <button type="submit">Promjeni</button>
      </form>
      <br></br>
      <hr></hr>
      <br></br>
      <h2>Dodaj ili obrisi akciju</h2>
      <a 
      className="Pomoc"
      href="none"
  data-tooltip-id="my-tooltip3" 
  data-tooltip-place="top"
>
<a href="google.com"><FontAwesomeIcon className='Pomoc' icon={faQuestionCircle}/></a>
</a>
<Tooltip id="my-tooltip3" >
  <p>Dodavanje ili brisanje akcije se koristi kako bi ste stavili popust na neki proizvod. Da bi ste dodali neku akciju prvo odaberete produkt, 
    onda stavite  akciju tj novu cijenu i odaberete vrstu proizvoda </p><p> tj gdje se on nalazi npr ako želite smanjiti cijenu za neku klimu prvo je odaberite i u vrsta proizvoda takođe odaberete klima.
   A za brisanje samo odaberete proizvod i vrstu proizvoda. </p>
</Tooltip>
      <form >
      <select value={selectedProduct?.id || ''} onChange={handleProductSelect}>
        <option value="">Odaberi proizvod</option>
        {products.map((product) => (
          <option key={product.id} value={product.id}>
            {product.ime}
          </option>
        ))}
      </select>
      <br />
      <label>
        Akcija:
        <input type="number" value={akcijaValue || ''} onChange={handleAkcijaChange} />
      </label>
      <label>Vrsta proizvoda</label>
      <select value={vrstaProdukta} onChange={(e) => setVrstaProdukta(e.target.value)}>
        {categorys.map((product) => (
          <option key={product.id} value={product.id}>
            {product.ime}
          </option>
        ))}
        </select>
      <br />
      <button disabled={!selectedProduct} onClick={handleAkcijaAdd}>
        Dodaj akciju
      </button>
      <br />
      <br />
      <button disabled={!selectedProduct} onClick={handleAkcijaDelete}>
        Obriši akciju
      </button>
      </form>
    </div>
);
};

export default Dodaj;