import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {collection, getDocs, query, orderBy, where, doc , deleteDoc  } from "firebase/firestore";
import { db } from "./Firebase";
import Select from "react-select";
import { AuthContext } from "./context/AuthContext";
import { useContext } from "react";

const useFetch = (url, searchQuery, sortBy, sortOrder, selectedTags) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      

      try {
        let q = query(collection(db, "Proizvodi"), orderBy(sortBy, sortOrder));
        
        if (selectedTags.length > 0) {
          q = query(q, where("tagovi", "array-contains-any", selectedTags));
        }
        

        const querySnapshot = await getDocs(q);
       
        let list = [];
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        
        if (searchQuery) {
          list = list.filter((item) =>
            item.ime.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }

        setData(list);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [searchQuery, sortBy, sortOrder, selectedTags]);

  // return an object with the data and setData function
  return { data, setData };
};







const Bloglist = ({ items, title, img, handleDelete, selectedTags }) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <div className="blog-list">
      <h1>{title}</h1>
      {selectedTags && selectedTags.length > 0 && (
        <p>
          Selected tags:{" "}
          {selectedTags.map((tag) => (
            <span key={tag}>{tag}&nbsp;</span>
          ))}
        </p>
      )}
      {items.length === 0 ? (
        <h1>Nema proizvoda sa ovim tagovima molimo promjenite</h1>
      ) : (
        <div className="gridview">
          {items.map((item) => (
            <div className="blog-preview" key={item.id}>
              <Link className="style-link" to={`/opsirnije/${item.id}`}>
                <h4>{item.ime}</h4>
              </Link>
              <img className="Itempicture" alt="slika" src={item.image} />
              {item.akcija ? (
                <p>
                  Stara cijena : <del>{item.cijena}</del> KM<br></br>
                  Nova cijena: {item.akcija} KM
                </p>
              ) : (
                <p>Cijena: {item.cijena} KM</p>
              )}
              {currentUser ? (
              <>
                <button
                  style={{ margin: "5px" }}
                  className="deletebtn"
                  onClick={() => handleDelete(item.id)}
                >
                  Delete
                </button>
                <Link to={`/opsirnije/${item.id}`}>
                  <button
                    className="opsirnije-btn"
                    onClick={() => console.log("Opsirnije clicked")}
                  >
                    Opširnije
                  </button>
                </Link>
              </>
            ) : (
              <>
              <br></br>
              <Link to={`/opsirnije/${item.id}`}>
              <button
                className="opsirnije-btn"
                onClick={() => console.log("Opsirnije clicked")}
              >
                Opsirnije
              </button>
              </Link>
              </>
            )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};




const Home = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("ime");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedTags, setSelectedTags] = useState([]);
  const { data: items} = useFetch("", searchQuery, sortBy, sortOrder, selectedTags); // destructure setData function
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      const querySnapshot = await getDocs(collection(db, 'Tagovi'));
      const options = querySnapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.id
      }));
      setOptions(options);
      
    };
    fetchOptions();
  }, []);

  const handleDelete = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    if (confirmDelete) {
      deleteDoc(doc(db, "Proizvodi", id))
        .then(() => {
          console.log("Document successfully deleted!");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    }
  };
  

  
  
  

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortBy = (event) => {
    setSortBy(event.target.value);
  };

  const handleSortOrder = (event) => {
    setSortOrder(event.target.value);
  };

  const handleTagSelect = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    // extract tag values from selected options
    const tags = selectedOptions.map((option) => option.value);
    setSelectedTags(tags);
  };
  

  return (
    <div className="home">
      <div className="sort">
        <input
          className="Searchbar"
          type="text"
          placeholder="Pretražite..."
          onChange={handleSearch}
        />
        <label>Sortiraj po:&nbsp;</label>
        <select value={sortBy} onChange={handleSortBy}>
          <option value="ime">Imenu</option>
          <option value="cijena">Cijena</option>
        </select>
        &nbsp;
        &nbsp;
        <label>Po redoslijedu &nbsp;</label>
        <select value={sortOrder} onChange={handleSortOrder}>
          <option value="asc">Ascending</option>
          <option value="desc">Descending</option>
        </select>
        &nbsp;  &nbsp;
        &nbsp;
        &nbsp;
        <label>Odaberite željene kategorije: &nbsp;</label>
        <Select
          options={options}
          isMulti
          value={selectedOptions}
          onChange={handleTagSelect}
          className="option"
        />
      </div>
      <div className="blog">
        {items && (
          <>
            <Bloglist
              items={items}
              title="Proizvodi"
              handleDelete={(handleDelete)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Home;