import './Info.css'
const Info = () => {
    return (  <><section className="wd-title-bar">
        <div className="row">

<div className="large-12 columns p-t-70 text-left">

    <h2 id="page-title" className="title">O nama</h2>


    
        <h5>Fudo mont</h5>

    
</div>


</div>
    </section>
    <br/>
   <div>
    <p>DOO FUDOMONT SREBRENIK je firma sa 
        dugogodišnjim iskustvom u poslovima vodoinstalacije i centralnog grijanja.</p>
        <p>Kontakt telefon:  061 195 565</p>
        <p>Vlasnik: Glumčević Fuad</p>
   </div>
   </>
    );
}
 
export default Info;