import NavBar from './Navbar';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Create from './Create';
import Footer from './Footer';
import Info from './Info';
import Pocetna from './Pocetna';
import Login from './Login';
import Dodaj from './Dodaj';
import { useContext } from 'react';
import { AuthContext } from "./context/AuthContext";
import Opsirnije from './Opsirnije';
import Konvertori from './Konvertori';
import Klime from './Klime';
import Slider from './Slider';
import Hisense from './Hisense';

function App() {

  const { currentUser } = useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    if (currentUser) {
      return children;
    } else {
      window.location.href = '/login';
      return null;
    }
  }

  return (
    <Router>
      <div className="App">
        <NavBar />
        <Switch>
          <Route exact path="/">
            <Slider />
            <div className="content">
              <Pocetna />
            </div>
          </Route>
          <Route exact path="/Konvertori">
            <div className="content">
              <Konvertori />
            </div>
          </Route>
          <Route exact path="/Klime">
            <div className="content">
              <Klime />
            </div>
          </Route>
          <Route exact path="/proizvodi">
            <div className="content">
              <Home />
            </div>
          </Route>
          <Route exact path="/kontakt">
            <div className="content">
              <Create />
            </div>
          </Route>
          <Route exact path="/opsirnije/:id">
            <div className="content">
              <Opsirnije />
            </div>
          </Route>
          <Route exact path="/onama">
            <div className="content">
              <Info />
            </div>
          </Route>
          <Route exact path="/login">
            <div className="content">
              <Login />
            </div>
          </Route>
          <Route exact path="/dodaj">
            <RequireAuth>
              <div className="content">
                <Dodaj />
              </div>
            </RequireAuth>
          </Route>
          <Route exact path="/Hisense">
            <div className="content">
              <Hisense/>
            </div>
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
