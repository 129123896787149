import React from 'react';
import { useState,useEffect } from 'react';
import { db } from './Firebase';
import { doc, getDoc } from 'firebase/firestore';

const DiscountBanner = () => {
  const [bannerText, setBannerText] = useState("");

  useEffect(() => {
    const fetchBannerText = async () => {
      const docRef = doc(db, "DiscountBanner", "Banner1");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setBannerText(docSnap.data().TekstBannera);
      }
    }
    fetchBannerText();
  }, []);

  
  return (<>
              {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
              <marquee className="discount-banner">{bannerText}</marquee>
              
      </>
  );
};

export default DiscountBanner;
