import { Link } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {AuthContext} from ".//context/AuthContext";
import { useContext } from 'react';

const NavBar = () => {
  const [showLinks, setShowLinks] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const {currentUser} = useContext(AuthContext)

  const links = [
    { label: "Pocetna", link: "/", id:"1" },
    { label: "Proizvodi", link: "/proizvodi", id:"2" },
    { label: "O nama", link: "/onama", id:"3" },
    { label: "Kontakt", link: "/kontakt", id:"4" },
    { label: "Login", link:"/login", id:"5"},
    { label: "Katalozi", link:"/Hisense", id:"6"},
  ];

  if (currentUser) {
    links.push({ label: "Dodaj", link: "/dodaj" });
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="navbar">
      <img
        src="../android-chrome-192x192.png"
        alt="Fudo Mont Srebrenik"
        className="navImage"
      ></img>
      <h1>Fudo Mont Srebrenik</h1>
        {windowWidth > 800 ? (
          <>
          <div className="links">
            {links.map((link) => (
              <Link key={link.id} to={link.link}>{link.label}</Link>
              
            ))}
            </div>
          </>
        ) : (
          <>
            <button
              className="menu-button"
              onClick={() => setShowLinks(!showLinks)}
            >
              <FontAwesomeIcon className="fa-2x" icon={faBars}></FontAwesomeIcon>
            </button>
            {showLinks && (
                <div className="links-container">
              <ul className="list">
                {links.map((link) => (
                  <li>
                    <Link key={link.id} to={link.link}>{link.label}</Link>
                  </li>
                ))}
              </ul>
              </div>
            )}
          </>
        )}
    </nav>
  );
};

export default NavBar;
