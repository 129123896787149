import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./Firebase";

const Opsirnije = () => {
  const [item, setItem] = useState(null);
  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const itemDoc = await getDoc(doc(db, "Proizvodi", id));
        if (itemDoc.exists()) {
          setItem(itemDoc.data());
          if (itemDoc.data().extraImages) {
            setImages([itemDoc.data().image, ...itemDoc.data().extraImages]);
            setMainImage(itemDoc.data().image);
          } else {
            setImages([itemDoc.data().image]);
            setMainImage(itemDoc.data().image);
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error fetching document:", error);
      }
    };
    fetchItem();
  }, [id]);

  const handleImageClick = (src) => {
    const mainImageEl = document.getElementById("mainImage");
    mainImageEl.style.opacity = 0;
    setTimeout(() => {
    setMainImage(src);
    mainImageEl.style.opacity = 1;
    }, 500);
    };

  return (
    <div className="blog-details">
      {item ? (
        <div>
          <h1>{item.ime}</h1>
          <img className="opsirnijepicture" alt="slika" id="mainImage" src={mainImage}/>
          
          {images.length >= 1 && (
            <>
            <div className="extra-images">
              {images.map((imgSrc) => (
                <img
                  key={imgSrc}
                  alt="slika"
                  src={imgSrc}
                  className={
                   "extra-image"
                  }
                  onClick={() => handleImageClick(imgSrc)}
                />
              ))}
            </div>
            </>
          )}
          <p className="cijenaproizvoda">Cijena:
           {item.cijena} KM</p>
          <p>{item.opis}</p>
          {item.manualLink && (
            <div className="manual">
              <a href={item.manualLink}>Upute za korisnika</a>
            </div>
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Opsirnije;
