import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "./Firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { AuthContext } from "./context/AuthContext";
import { useContext } from "react";

const useFetch = (url, searchQuery, sortBy, sortOrder) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const q = query(collection(db, "Kondenzator"), orderBy(sortBy, sortOrder));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        if (searchQuery) {
          list = list.filter((item) =>
            item.ime.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }
        setData(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [searchQuery, sortBy, sortOrder]);
  return { data };
};

const Bloglist = ({ items, title, img, handleDelete }) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div className="blog-list">
      <h1>{title}</h1>
      <div className="gridview">
        {items.map((item) => (
          <div className="blog-preview" key={item.id}>
            <Link className="style-link" to={`/opsirnije/${item.id}`}>
              <h4>{item.ime}</h4>
            </Link>
            <img className="Itempicture" alt="slika" src={item.image} />
            <p>Cijena : {item.cijena} KM</p>
            <p>{item.kratakOpis}</p>
            {currentUser ? (
              <>
                <button
                  style={{ margin: "5px" }}
                  className="deletebtn"
                  onClick={() => handleDelete(item.id)}
                >
                  Delete
                </button>
                <Link to={`/opsirnije/${item.id}`}>
                  <button
                    className="opsirnije-btn"
                    onClick={() => console.log("Opsirnije clicked")}
                  >
                    Opsirnije
                  </button>
                </Link>
              </>
            ) : (
              <button
                className="opsirnije-btn"
                onClick={() => console.log("Opsirnije clicked")}
              >
                Opsirnije
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Konvertori = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("ime");
  const [sortOrder, setSortOrder] = useState("asc");
  const { data: items } = useFetch("", searchQuery, sortBy, sortOrder);
  const handleDelete = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    if (confirmDelete) {
      deleteDoc(doc(db, "Proizvodi", id))
      deleteDoc(doc(db, "Kondenzator", id))
        .then(() => {
          console.log("Document successfully deleted!");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    };
    
    const handleSortBy = (event) => {
    setSortBy(event.target.value);
    };
    
    const handleSortOrder = (event) => {
    setSortOrder(event.target.value);
    };
    
    
    
    return (
    <div className="home">
    <div className="sort">
    <input
          className="Searchbar"
           type="text"
           placeholder="Search products"
           onChange={handleSearch}
         />
    <label>Sort By:&nbsp;</label>
    <select value={sortBy} onChange={handleSortBy}>
    <option value="ime">Ime</option>
    <option value="cijena">Price</option>
    </select>
    <label>Sort Order: &nbsp;</label>
    <select value={sortOrder} onChange={handleSortOrder}>
    <option value="asc">Ascending</option>
    <option value="desc">Descending</option>
    </select>
    </div>
    {items ? (
    <Bloglist
           items={items}
           title="All products"
           handleDelete={handleDelete}
         />
    ) : (
    <div className="loading">Loading...</div>
    )}
    </div>
    );
    };
    
    export default Konvertori;
