import { Link } from "react-router-dom";
const Pocetna = () => {

      return (
        <div className="blog-list-pocetna">
          <h1>Proizvodi</h1>
          <div className="gridview-pocetna">
              <div className="blog-preview">
                <Link className="style-link" to='/Klime' >
                  <h2>Klime</h2>
                </Link>
                <Link to="/Klime">
                <img className="Itempicture" alt="slika" src="https://firebasestorage.googleapis.com/v0/b/fudomont-srebrenik.appspot.com/o/images%2Fklima1.png?alt=media&token=9d8b9431-d00c-42d0-9dc9-237019a40071" />
                </Link>
                </div>
                <div className="blog-preview">
                <Link className="style-link" to='/Konvertori' >
                  <h2>Konvertori</h2>
                </Link>
                <Link to="/Konvertori">
                <img className="Itempicture" alt="slika" src="https://firebasestorage.googleapis.com/v0/b/fudomont-srebrenik.appspot.com/o/images%2Fklima1.png?alt=media&token=9d8b9431-d00c-42d0-9dc9-237019a40071" />
                </Link>
                </div>
                <div className="blog-preview">
                <Link className="style-link" to='/Grijaci' >
                  <h2>Toplotni Grijaci</h2>
                </Link>
                <Link to="/Grijaci">
                <img className="Itempicture" alt="slika" src="https://firebasestorage.googleapis.com/v0/b/fudomont-srebrenik.appspot.com/o/images%2Fklima1.png?alt=media&token=9d8b9431-d00c-42d0-9dc9-237019a40071" />
                </Link>
                </div>
                </div>
                
              </div>
      )
}
 
export default Pocetna