import { useState } from "react";
import { useHistory } from "react-router-dom";
import emailjs from 'emailjs-com';

const Create = () => {
    const [ime,setIme] = useState('');
    const [body,setBody] = useState('');
    const [broj,setBroj] = useState('');
    const [isPending, setIsPending] = useState(false);
    const history = useHistory();
    const serviceId = 'service_g65jhld';
    const templateId = 'template_pza0mxx';
    const userId = 'XP5yOJ108Qzb3bpHn';
    

    const sendEmail = (e) => {
        e.preventDefault();
      
        const templateParams = {
          from_name: ime,
          to_name: 'Fudo-Mont-Srebrenik',
          message: body,
          phone_number: broj
        };
      
        emailjs.send(serviceId, templateId, templateParams, userId)
          .then((response) => {
            alert("Vasa poruka je uspjesno poslata");
            console.log('SUCCESS!', response.status, response.text);
          }, (error) => {
            console.log('FAILED...', error);
          });
      
        setIsPending(true);
        setTimeout(() => {
          setIsPending(false);
          history.push('/');
        }, 3000);
      }
      
      return (
        <div className="create">
          <h2>Kontaktirajte nas</h2>
          <form onSubmit={sendEmail}>
            <label>Ime i prezime</label>
            <input
              type="text"
              required
              value={ime}
              onChange={(e) => setIme(e.target.value)}
            />
            <label>Upit</label>
            <textarea
              required
              value={body}
              onChange={(e) => setBody(e.target.value)}
            ></textarea>
            <label>Broj telefona</label>
            <input
              type="tel"
              pattern="[0-9]{3}[0-9]{3}[0-9]{3}|[0-9]{3}[0-9]{3}[0-9]{4}"
              required
              value={broj}
              onChange={(e) => setBroj(e.target.value)}
            />
            {!isPending && <button>Send</button>}
            {isPending && <button disabled>Sending...</button>}
          </form>
          <br />
          <h3>Možete nas kontaktirati putem telefona na broj:  061 195 565</h3>
        </div>
      );
      
}
 
export default Create;