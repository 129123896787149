import React from 'react';
import {
    MinimalButton,
    ScrollMode,
    SpecialZoomLevel,
    Viewer,
    ViewMode,
    
} from '@react-pdf-viewer/core';
import { NextIcon, pageNavigationPlugin, PreviousIcon } from '@react-pdf-viewer/page-navigation';
import { ThumbnailDirection, thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { zoomPlugin } from '@react-pdf-viewer/zoom';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

const Hisense = ({ fileUrl }) => {
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToNextPage, jumpToPreviousPage } = pageNavigationPluginInstance;

    const thumbnailPluginInstance = thumbnailPlugin();
    const { Thumbnails } = thumbnailPluginInstance;

    const zoomPluginInstance = zoomPlugin();
    const { Zoom } = zoomPluginInstance;

    return (
        <div
            style={{
                border: '1px solid rgba(0, 0, 0, .3)',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .3)',
                    height: '40rem',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '1rem',
                        transform: 'translate(0, -100%) rotate(-90deg)',
                        zIndex: '1',
                    }}
                >
                    <MinimalButton onClick={jumpToPreviousPage}>
                        <PreviousIcon />
                    </MinimalButton>
                </div>
                <Viewer
                    defaultScale={SpecialZoomLevel.PageFit}
                    scrollMode={ScrollMode.Page}
                    viewMode={ViewMode.DualPageWithCover}
                    fileUrl='https://firebasestorage.googleapis.com/v0/b/fudomont-srebrenik.appspot.com/o/PDFOVI%2Fhisense%20katalog-compressed.pdf?alt=media&token=09d6a697-087e-48c7-9c4c-3558d1190950'
                    plugins={[
                        pageNavigationPluginInstance,
                        thumbnailPluginInstance,
                        zoomPluginInstance,
                    ]}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '1rem',
                        transform: 'translate(0, -100%) rotate(-90deg)',
                        zIndex: '1',
                    }}
                >
                    <MinimalButton onClick={jumpToNextPage}>
                        <NextIcon />
                    </MinimalButton>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1rem',
                    
                }}
            >
                              <div style={{ border: '1px solid white', borderRadius : '20%' }}>

                <Zoom
                    style={{
                      border: '1px solid white',
                      borderRadius: '4px',
                      padding: '4px',
                  }}
                    zoomPluginInstance={zoomPluginInstance}
                    scaleLevels={[
                        SpecialZoomLevel.PageFit,
                        SpecialZoomLevel.PageWidth,
                        0.5,
                        0.75,
                        1,
                        1.25,
                        1.5,
                        2,
                        3,
                        4,
                    ]}
                />
            </div>
            </div>
            <div
                style={{
                    height: '12rem',
                }}
            >
                <Thumbnails thumbnailDirection={ThumbnailDirection.Horizontal} />
            </div>
        </div>
    );
};

export default Hisense;
