import { useState } from 'react';
import './Slider.css';
import 'animate.css'
import 'animate.css/animate.min.css';


const images = [
  {
    url: 'https://as2.ftcdn.net/v2/jpg/04/05/50/27/1000_F_405502760_CcCGQYKXBYIBrlbyjbn7mFoYZhwxIi1K.jpg',
    text: 'Naša kompanija nudi najkvalitetnije klima uređaje na tržištu. Naši klima uređaji su efikasni, pouzdani i energetski štedljivi.    ',
  },
  {
    url: 'https://thumbs.dreamstime.com/b/installation-air-conditioning-man-installs-indoor-unit-conditioner-56574663.jpg',
    text: 'Klimatizacija je ključna za udobnost u vašem domu ili poslovnom prostoru. Naši stručnjaci će vam pomoći da pronađete najbolje rješenje za vaše potrebe.    ',
  },
  {
    url: 'https://i.guim.co.uk/img/media/3aab8a0699616ac94346c05f667b40844e46322f/0_123_5616_3432/master/5616.jpg?width=1024&quality=85&dpr=1&s=none',
    text: 'Sa našim klima uređajima, možete biti sigurni da ćete uživati u čistom i svježem zraku u svakoj prostoriji. Naši uređaji su dizajnirani da uklanjaju štetne čestice iz zraka.',
  },
  {
    url: 'https://thumbs.dreamstime.com/b/installation-air-conditioning-man-installs-indoor-unit-conditioner-56574663.jpg',
    text: 'Mi se ponosimo što nudimo vrhunsku podršku našim kupcima. Naša usluga uključuje brzu dostavu i instalaciju klima uređaja, kao i stručno održavanje i popravke.',
  },
];

const Slider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrev = () => {
    setActiveIndex(activeIndex === 0 ? images.length - 1 : activeIndex - 1);
  };

  const handleNext = () => {
    setActiveIndex(activeIndex === images.length - 1 ? 0 : activeIndex + 1);
  };

  return (
    <div className="slider-container">
      {images.map((image, index) => (
        <div
          key={index}
          className={`slider-image ${
            index === activeIndex ? 'active animate__animated' : 'animate__animated '
          }`}
          style={{ backgroundImage: `url(${image.url})` }}
        >
          <p>{image.text}</p>
        </div>
      ))}
      <button onClick={handlePrev} className="slider-button prev">
        &#8249;
      </button>
      <button onClick={handleNext} className="slider-button next">
        &#8250;
      </button>
    </div>
  );
};

export default Slider;
