import { useState } from "react";
import { useHistory } from "react-router-dom";
import { signInWithEmailAndPassword} from "firebase/auth";
import { auth } from "./Firebase";
import { useContext } from "react";
import {AuthContext} from ".//context/AuthContext";
import {signOut } from "firebase/auth";
const Login = () => {
  
    const [error, setError] = useState(false);
    const [ime,setIme] = useState('');
    const [password,setPassword] = useState('');
    const history = useHistory();
    const {dispatch} = useContext(AuthContext)
    const handleSubmit = (e) => {
      e.preventDefault();
        signInWithEmailAndPassword(auth, ime, password)
        .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        dispatch({type:"LOGIN", payload:user})
        // ...
        history.push('/dodaj')
       
        
  })
  .catch((error) => {
    setError(true);
  });}

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        dispatch({ type: "LOGOUT" });
        history.push('/proizvodi');
      })
      .catch((error) => {
        console.log(error);
      });
  };
    return (
        <div className="create">
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <label>Email</label>
                <input
                type="text"
                required
                value={ime}
                onChange={(e) => setIme(e.target.value)}>
                </input>
                <label>Password</label>
                <input
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}>
                </input>
                <button type="submit">Login</button>
            </form>
            <br />
            <button onClick={handleLogout}>Log out</button>
            {error && <p>Invalid email or password</p>}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}
 
export default Login;