import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faTwitter,faLinkedin,faGithub } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import DiscountBanner from './DiscountBanner';
const Footer = () => {
    return ( 
		<>
		
		<div className='Footers'>
		<DiscountBanner/>
			<br />
      <footer className="footer-distributed">

			<div className="footer-right">

				<a href="https://www.facebook.com/fudomont.srebrenik"><FontAwesomeIcon className='Icons'  icon={faFacebook} /></a>
				<a href="google.com"><FontAwesomeIcon className='Icons' icon={faTwitter}/></a>
				<a href="google.com"><FontAwesomeIcon className='Icons' icon={faLinkedin}/></a>
				<a href="google.com"><FontAwesomeIcon className='Icons' icon={faGithub}/></a>

			</div>

			<div className="footer-left">

				<p className="footer-links">
					<Link to="/">Početna</Link>
					<Link to="/kontakt">Kontakt</Link>
					<Link to="/onama">O nama</Link>
				</p>

				<p>Fudo Mont Srebrenik
				</p>
				<p>
					Made by OM
				</p>
			</div>

		</footer>
		</div>
		</>
     );
}
 
export default Footer;